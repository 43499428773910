import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import Modal from 'react-bootstrap/Modal';
import Apis from '../../../../api';
import keyBy from 'lodash/keyBy'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import swal from 'sweetalert'
import config from '../../../../config';

const headers = [
    { title: '#', prop: 'id', isFilterable: false, isSortable: true },
    { title: 'Title', prop: 'title', isFilterable: true, isSortable: true },
    { title: 'Description', prop: 'description', isFilterable: false, isSortable: true },
    { title: 'Created By', prop: 'created_by', isFilterable: true, isSortable: true },
    { title: 'Date', prop: 'date', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' },

];

const allowedTypes = [
    "application/msword",                 // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/pdf",                    // .pdf
    "application/vnd.ms-powerpoint",      // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.ms-excel",           // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "text/plain",                         // .txt
    "application/rtf",                    // .rtf
    "application/vnd.oasis.opendocument.text" // .odt
];

const maxFileSize = 5 * 1024 * 1024; // 5 MB

const PermitRegisterTable = ({ reload }) => {
    const [permitList, setPermitList] = React.useState([])
    const [permits, setPermits] = React.useState([])
    const [members, setMembers] = React.useState([])
    const [refresh, setRefresh] = React.useState(false);
    const [permit_popup, setPermitPopup] = React.useState(false);
    const [permitForm, setPermitForm] = React.useState({
        title: null,
        description: null,
        upload_file: null,
        _id: null
    })
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    React.useEffect(() => {
        var raw = {};
        Apis.getPermit(raw).then((res) => {
            setPermits(res.data.permits)
        }).catch((error) => {
            console.log(error)
        })
    }, [members, reload, refresh])

    React.useEffect(() => {
        Apis.getAllMember().then((res) => {
            setMembers(res.data.data.member)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const memberById = keyBy(members, '_id')

    React.useEffect(() => {
        var permit_list = [];
        let demo = null
        permits && permits.map((elm, index) => {
            if (elm.is_active) {
                demo = {
                    id: parseInt(index) + parseInt(1),
                    title: elm.title,
                    description: elm.description,
                    created_by: memberById[elm.created_by]?.role === 'admin' ? memberById[elm.created_by]?.first_name : memberById[elm.created_by]?.first_name + ' ' + memberById[elm.created_by]?.last_name,
                    date: new Date(elm.created_date).getDate() + '/' + parseInt(new Date(elm.created_date).getMonth() + 1) + '/' + new Date(elm.created_date).getFullYear(),
                    status: false,
                    action: <>
                        <div className="d-flex addremove_btn">
                            <button type="button" className="btn btn-primary btn-sm me-1" data-bs-placement="bottom" title="Edit" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                            <button className="btn btn-primary btn-sm me-1" onClick={() => handleDownload(elm)}><i className="fa fa-download"></i></button>
                            <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeletePermit(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                        </div>
                    </>
                }
            } else {
                demo = {
                    id: parseInt(index) + parseInt(1),
                    title: elm.title,
                    description: elm.description,
                    created_by: memberById[elm.created_by]?.role === 'admin' ? memberById[elm.created_by]?.first_name : memberById[elm.created_by]?.first_name + ' ' + memberById[elm.created_by]?.last_name,
                    date: new Date(elm.created_date).getDate() + '/' + parseInt(new Date(elm.created_date).getMonth() + 1) + '/' + new Date(elm.created_date).getFullYear(),
                    status: 'line-through',
                    action: <> <div className="d-flex addremove_btn">

                        <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeletePermit(elm._id, 1)}><i class="fas fa-trash-restore"></i></button>
                    </div></>
                }
            }

            permit_list.push(demo);
            return permit_list;

        })
        setPermitList(permit_list)
    }, [permits])

    const handleDeletePermit = (id, status) => {
        let title = ''
        let message = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
            message = "Permit archive successfully"
        } else {
            title = "Are you sure want to restore?"
            message = "Permit restore successfully"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Something went wrong !',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        '_id': id,
                    }
                    Apis.deletePermit(raw).then((res) => {
                        setError({
                            ...error,
                            error: message,
                            color: 'success',
                            open: true
                        })
                        setRefresh(!refresh)
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        })
    }

    const handleEdit = (permit) => {
        console.log(permit)
        setPermitPopup(true)
        setPermitForm(permit)
    }

    const handleSubmit = (formData) => {

        if (!formData.title) {
            setError({
                ...error,
                error: 'Title is required.',
                color: 'error',
                open: true
            })
            return;
        }
        if (!formData.description) {
            setError({
                ...error,
                error: 'Description is required.',
                color: 'error',
                open: true
            })
            return;
        }

        const newformData = new FormData();
        newformData.append('id', formData._id)
        newformData.append('title', formData.title);
        if (formData.upload_file) {
            newformData.append('files', formData.upload_file);
        }
        newformData.append('description', formData.description);

        Apis.updatePermit(newformData).then((res) => {
            setError({
                ...error,
                error: 'Permit Updated',
                color: 'success',
                open: true
            })
            setRefresh(!refresh)
            setPermitPopup(false)
            setPermitForm({ ...permitForm, upload_file: null, description: null, title: null })
        }).catch((error) => {
            setError({
                ...error,
                error: 'Some Thing Went Wrrong!',
                color: 'error',
                open: true
            })
        });
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            setError({
                ...error,
                error: 'Please select a file.',
                color: 'error',
                open: true
            })
            setPermitForm({ ...permitForm, upload_file: null })
            return;
        }

        // File type validation
        if (!allowedTypes.includes(selectedFile.type)) {
            setError({
                ...error,
                error: 'Invalid file type. Allowed types: DOC, DOCX, PDF, PPT, PPTX, XLS, XLSX, TXT, RTF, ODT.',
                color: 'error',
                open: true
            })
            setPermitForm({ ...permitForm, upload_file: null })
            return;
        }

        // File size validation
        if (selectedFile.size > maxFileSize) {
            setError({
                ...error,
                error: 'File size exceeds the 5MB limit.',
                color: 'error',
                open: true
            })
            setPermitForm({ ...permitForm, upload_file: null })
            return;
        }
        setPermitForm({ ...permitForm, upload_file: selectedFile })
        setError({
            ...error,
            open: false,
        })
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = `${config.api_url}/${file.file_path.path}`;
        link.download = file.title || 'download';
        link.type = file.file_path.file_mimetype;
        link.target = '_blank'
        link.click();

    };

    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={() => setError({
                    ...error,
                    open: false,
                })}
            >
                <Alert
                    onClose={() => setError({
                        ...error,
                        open: false,
                    })}
                    severity={error.color}
                    sx={{ width: '100%' }}>
                    {error.error}
                </Alert>
            </Snackbar>
            <DatatableWrapper
                body={permitList}
                headers={headers}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter placeholder="Type here to search" />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody rowProps={(row) => ({
                        style: { background: row.is_registered, textDecorationLine: row.status }
                    })} />
                </Table>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >

                    </Col>
                </Row>
            </DatatableWrapper>

            <Modal show={permit_popup} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setPermitPopup(!permit_popup)} centered >
                <Modal.Header closeButton>
                    <Modal.Title> Update Permit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="col-form-label">Title</label>
                                <input type="text" name='title' id="title" className="form-control" placeholder='Enter title' value={permitForm.title} onChange={(event) => setPermitForm({ ...permitForm, title: event.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="fileInput" className="col-form-label">Upload file ( DOC, DOCX, PDF, PPT, PPTX, XLS, XLXS, TXT, RTF, ODT)</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="files"
                                    id="fileInput"
                                    accept=".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .txt, .rtf, .odt"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div class="mb-3">
                                <label htmlFor="description" class="col-form-label">Description</label>
                                <textarea id="description" name="description" rows="3" class="form-control" placeholder="Enter Description" value={permitForm.description} onChange={(event) => setPermitForm({ ...permitForm, description: event.target.value })}></textarea>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit(permitForm)}  > Update</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setPermitPopup(!permit_popup)}>Close</button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default PermitRegisterTable;