import React from 'react'
import Auth from './Auth'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie'
import config from '../../config'
import Apis from '../../api'

function Login() {
    const { values, error, color, open, handleChange, handleClose, handleLogin } = Auth()
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })
    const [loginImage, setLoginImage] = React.useState({
        id: '',
        BackgroundImage: '',
        show_text: true,
    })
    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const loginImageSetting = data.find(setting => setting.key === 'login_image');
                const loginImageTextShow = data.find(setting => setting.key === 'show_login_image_text');
                if (loginImageSetting) {
                    setLoginImage({
                        ...loginImage,
                        id: loginImageSetting._id,
                        BackgroundImage: config.api_url + '/api/login-image/view/' + loginImageSetting._id,
                        show_text: loginImageTextShow ? loginImageTextShow.value : true
                    })
                }

            } else {
                setLoginImage({
                    ...loginImage,                  
                    BackgroundImage:'./assets/images/login-background.jpg',
                   
                })
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, []);
    React.useEffect(() => {
        let x = Cookies.get('login')
        if (x === undefined) {
            localStorage.removeItem('user-info')
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>Login | P2IC</title>
            </Helmet>
            <Snackbar
                open={open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            <div class="main_root">
                <div
                    className="accountbg"
                    style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${loginImage.BackgroundImage})`,
                    }}
                >
                </div>
                <div className='login_background ' style={loginImage.show_text ? { display: 'block' } : { display: 'none' }}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='login_background_text text_1'><span className="login_background_span" >Discipline</span> leads to habits.</div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='login_background_text text_2'><span className="login_background_span" >Habits</span> lead to consistency.</div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='login_background_text text_3'><span className="login_background_span" >Consistency</span> lead to results.</div>
                        </div>
                    </div>
                </div>



                <div className="account_loginpage" >
                    <div className="login-logo mt-4">
                        <Link to="/"><img src="./assets/images/logo-light.png" alt='' /></Link>
                    </div>
                    <div className="text-center mt-5" >
                        <h4 className='font-size-18 mt-5 text-center'>Welcome Back !</h4>
                        <p className='text-muted text-center'>Sign in to continue to P2IC.</p>
                    </div>
                    <form className="mt-4" onSubmit={handleLogin}>
                        <div className="form-group">
                            <label htmlFor="username">Email/Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Enter email or name"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name='password'
                                value={values.password}
                                onChange={handleChange}
                                placeholder="Enter Password"
                            />
                        </div>
                        <div className="form-group row align-items-center">
                            <div className="col-sm-6">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customControlInline"
                                        checked={values.isChecked}
                                        name="lsRememberMe"
                                        onChange={handleChange}
                                    />
                                    <label className="custom-control-label" htmlFor="customControlInline"> Remember me</label>
                                </div>
                            </div>
                            <div className="col-sm-6 submit_login">
                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                            </div>
                        </div>
                        <div className="form-group mt-2 mb-0 row">
                            <div className="col-12 mt-3">
                                <Link to="/forget-password">
                                    <i className="mdi mdi-lock"></i> Forgot your password?
                                </Link>
                            </div>
                        </div>
                    </form>

                    <div className="builtby_text text-center mt-5 pt-4">
                        <p className="text-start">
                            &copy; {(new Date().getFullYear())} P2IC. Built and Engineered by by Blue Ocean HPA
                        </p>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Login
