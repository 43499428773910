import React from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';
import PermitRegisterTable from './components/PermitRegisterTable';
import Modal from 'react-bootstrap/Modal';
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Apis from '../../../api';



const allowedTypes = [
    "application/msword",                 // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/pdf",                    // .pdf
    "application/vnd.ms-powerpoint",      // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.ms-excel",           // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "text/plain",                         // .txt
    "application/rtf",                    // .rtf
    "application/vnd.oasis.opendocument.text" // .odt
];

const maxFileSize = 5 * 1024 * 1024; // 5 MB

const PermitRegister = () => {

    const [permit_popup, setPermitPopup] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [permitForm, setPermitForm] = React.useState({
        title: null,
        description: null,
        upload_file: null
    })

    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            setError({
                ...error,
                error: 'Please select a file.',
                color: 'error',
                open: true
            })
            setPermitForm({ ...permitForm, upload_file: null })
            return;
        }

        // File type validation
        if (!allowedTypes.includes(selectedFile.type)) {
            setError({
                ...error,
                error: 'Invalid file type. Allowed types: DOC, DOCX, PDF, PPT, PPTX, XLS, XLSX, TXT, RTF, ODT.',
                color: 'error',
                open: true
            })
            setPermitForm({ ...permitForm, upload_file: null })
            return;
        }

        // File size validation
        if (selectedFile.size > maxFileSize) {
            setError({
                ...error,
                error: 'File size exceeds the 5MB limit.',
                color: 'error',
                open: true
            })
            setPermitForm({ ...permitForm, upload_file: null })
            return;
        }
        setPermitForm({ ...permitForm, upload_file: selectedFile })
        setError({
            ...error,
            open: false,
        })
    };



    const handleSubmit = (formData) => {

        if (!formData.title) {
            setError({
                ...error,
                error: 'Title is required.',
                color: 'error',
                open: true
            })
            return;
        }
        if (!formData.upload_file) {
            setError({
                ...error,
                error: 'Please select valid file.',
                color: 'error',
                open: true
            })
            return;
        }
        if (!formData.description) {
            setError({
                ...error,
                error: 'Description is required.',
                color: 'error',
                open: true
            })
            return;
        }
        const user = JSON.parse(localStorage.getItem('user-info'))
        const newformData = new FormData();
        newformData.append('title', formData.title);
        newformData.append('files', formData.upload_file);
        newformData.append('description', formData.description);
        newformData.append('created_by', user.id);
        newformData.append('created_date', new Date().getTime());

        Apis.addPermit(newformData).then((res) => {
            setError({
                ...error,
                error: 'New Permit Uploaded',
                color: 'success',
                open: true
            })
            setRefresh(!refresh)
            setPermitPopup(false)
            setPermitForm({ ...permitForm, upload_file: null, description: null, title: null })
        }).catch((error) => {
            setError({
                ...error,
                error: 'Some Thing Went Wrrong!',
                color: 'error',
                open: true
            })
        });
    }

    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={() => setError({
                    ...error,
                    open: false,
                })}
            >
                <Alert
                    onClose={() => setError({
                        ...error,
                        open: false,
                    })}
                    severity={error.color}
                    sx={{ width: '100%' }}>
                    {error.error}
                </Alert>
            </Snackbar>
            <div id="layout-wrapper">
                <Header title={'Permit Register| P2IC'}></Header>
                <Sidebar title='PermitRegister' show='PermitRegister'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">

                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Permit Register</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Permit Register</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow" >
                                <div className="col-lg-12"  >
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Permit Register</h4>
                                                <div>
                                                    <button type="button" className="btn btn-primary" onClick={() => setPermitPopup(!permit_popup)}>Add<i className="fas fa-plus"></i></button>
                                                </div>
                                            </div>
                                            <PermitRegisterTable
                                                reload={refresh}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>

            <Modal show={permit_popup} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setPermitPopup(!permit_popup)} centered >
                <Modal.Header closeButton>
                    <Modal.Title> Add New Permit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="title" className="col-form-label">Title</label>
                                <input type="text" name='title' id="title" className="form-control" placeholder='Enter title' value={permitForm.title} onChange={(event) => setPermitForm({ ...permitForm, title: event.target.value })} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="fileInput" className="col-form-label">Upload file ( DOC, DOCX, PDF, PPT, PPTX, XLS, XLXS, TXT, RTF, ODT)</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="files"
                                    id="fileInput"
                                    accept=".doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .txt, .rtf, .odt"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div class="mb-3">
                                <label htmlFor="description" class="col-form-label">Description</label>
                                <textarea id="description" name="description" rows="3" class="form-control" placeholder="Enter Description" value={permitForm.description} onChange={(event) => setPermitForm({ ...permitForm, description: event.target.value })}></textarea>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit(permitForm)}  > Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setPermitPopup(!permit_popup)}>Close</button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default PermitRegister;