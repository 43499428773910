import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Alert from '../../../helpers/Alert'
import CollaboratorApi from './CollaboratorApi'
import { SketchPicker } from 'react-color'
import EditableProjectCalendar from '../../../helpers/EditableProjectCalendar'
import AssociationTable from './components/AssociationTable'
import MemberTable from './components/MemberTable'
import TeamTable from './components/TeamTable'
import { default as ReactSelect } from "react-select"

const showAllOption = { value: '', label: '-Select Team-' }
const showAllOptionMemeber = { value: '', label: '-Select Member-' }
function ProjectCollaborator() {
    const { error,
        teamList,
        memberList,
        associationList,
        state,
        values,
        PROJECT_CALENDAR_DAYS,
        setError,
        handleClick,
        handleAdd,
        handleColorChange,
        handleChange,
        onTeamChange,
        handleTeamSubmit,
        handleMemberSubmit,
        handleDeleteTeam,
        handleDeleteMember,
        handleDeleteAssociation,
        handleEditTeam,
        handleAssociationSubmit,
        handleProjectCalendarEditStart,
        handleProjectCalendarCancel,
        handleForgetPassword,
        handleProjectCalendarSave } = CollaboratorApi()
    const [title, setTitle] = React.useState('Members')

    React.useEffect(() => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
    }, [title])


    const styles = {
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        colors: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${values.color}`,
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
            left: '70px',
            top: '10px'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },

    }



    const MembersSelect = (lists) => {
        let list = []
        if (lists.length > 0) {
            lists.member.map((elm, index) => {
                if (elm.status === 1 && elm.role !== 'admin') {
                    const demo = {
                        value: elm._id,
                        label: elm.first_name + ' ' + elm.last_name
                    }
                    list.push(demo)
                    return list
                }
                return list
            })
        }
        return list
    }


    const TeamSelect = (lists) => {

        let list = []
        if (lists.length > 0) {
            lists.team.map((elm, index) => {
                if (elm.status === 1) {
                    const demo = {
                        value: elm._id,
                        label: elm.name
                    }
                    list.push(demo)
                    return list
                }
                return list
            })
            return list
        }
        return list

    }

    const user = JSON.parse(localStorage.getItem('user-info'))



    return (
        <>
            <div id="layout-wrapper">
                <Header title={'Project Collaborators - ' + title + '  | P2IC'}></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='projectCollaborators'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">

                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Project Collaborators</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Collaborators</li>
                                            <li className="breadcrumb-item active">{title}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="button-items">
                                                <button className={(title === 'Members') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Members')} >Members</button>
                                                <button className={(title === 'Teams') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Teams')} >Teams</button>
                                                <button className={(title === 'Team Member Association') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Team Member Association')} >Team Member Association</button>

                                                <EditableProjectCalendar
                                                    editMode={state.isProjectCalendarEdited}
                                                    isLoading={state.isProjectCalendarLoading}
                                                    days={PROJECT_CALENDAR_DAYS}
                                                    disabled={state.isProjectCalendarLoading}
                                                    onEditStart={handleProjectCalendarEditStart}
                                                    onSave={handleProjectCalendarSave}
                                                    onCancel={handleProjectCalendarCancel}
                                                    defaultValues={state.projectCalendarValues}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row pr_tagsrow" >
                                <div className={(title === 'Members') ? "col-lg-12" : "col-lg-12 d-none"} >
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Members</h4>
                                                <div>
                                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" onClick={handleAdd}>Add<i className="fas fa-plus"></i></button>
                                                </div>

                                                <div className="modal fade" id="Members" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered members_modalbox">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">{(values.id !== '') ? 'Update Member' : 'Add Member'}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-lg-4 mt-2">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">First Name</label>
                                                                                <input type="text" className="form-control" id="recipient-name" name='first_name' value={values.first_name} onChange={handleChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 mt-2">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Last Name</label>
                                                                                <input type="text" className="form-control" id="recipient-name" name='last_name' value={values.last_name} onChange={handleChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 mt-2">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Abrv.</label>
                                                                                <input type="text" className="form-control" id="recipient-name" name='member_abrv' value={values.member_abrv} onChange={handleChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 mt-2">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                <input type="text" className="form-control" id="recipient-name" name='member_handle' value={values.member_handle} readOnly />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 mt-2">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Email</label>
                                                                                <input type="email" className="form-control" id="recipient-name" name='email' value={values.email} onChange={handleChange} disabled={(values.id !== '') ? true : false} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-primary" data-bs-dismiss={(values.email !== '') ? "modal" : ""} onClick={() => handleMemberSubmit(values)}>{(values.id !== '') ? 'Update Member' : 'Add Member'}</button>
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <MemberTable
                                                data={memberList}
                                                handleDeleteMember={handleDeleteMember}
                                                handleEditTeam={handleEditTeam}
                                                handleForgetPassword={handleForgetPassword}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className={(title === 'Teams') ? "col-lg-12" : "col-lg-12 d-none"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Teams</h4>
                                                <div className="paroject_datepicker">
                                                    <button type="button" className="btn btn-primary mx-1 float-start" data-bs-toggle="modal" data-bs-target="#Teammodal" data-bs-whatever="@mdo" onClick={handleAdd}>Add<i className="fas fa-plus"></i></button>
                                                    <div className="modal fade" id="Teammodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered team_modalbox">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">{(values.id !== '') ? 'Update Team' : 'Add Team'}</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="recipient-name" className="col-form-label">Team Name:</label>
                                                                                    <input type="text" className="form-control" id="recipient-name" name='team_name' value={values.team_name} onChange={handleChange} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="recipient-name" className="col-form-label">Abrv.</label>
                                                                                    <input type="text" className="form-control" id="recipient-name" name='team_abrv' value={values.team_abrv} onChange={handleChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                                                                    <input type="text" className="form-control" id="recipient-name" name='team_handle' value={values.team_handle} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="recipient-name" className="col-form-label">Planning Horizon</label>
                                                                                    <select className="form-select" aria-label="Default select example" name='team_planning' value={values.team_planning} onChange={handleChange}>
                                                                                        <option value=''>Select Planning Horizon</option>
                                                                                        <option value="Daily">Daily</option>
                                                                                        <option value="Weekly">Weekly</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Color</label>
                                                                                <div>
                                                                                    <div style={styles.swatch} onClick={handleClick} >
                                                                                        <div style={styles.colors} />
                                                                                    </div>
                                                                                    {state.show_color_picker && (
                                                                                        <div style={styles.popover} >
                                                                                            <div style={styles.cover} onClick={handleClick} />
                                                                                            <SketchPicker
                                                                                                color={values.color}
                                                                                                onChangeComplete={handleColorChange} />
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-primary" data-bs-dismiss={(values.team_planning !== '') ? "modal" : ""} onClick={() => handleTeamSubmit(values)}>{(values.id !== '') ? 'Update Team' : 'Add Team'}</button>
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <TeamTable
                                                data={teamList}
                                                handleDeleteTeam={handleDeleteTeam}
                                                handleEditTeam={handleEditTeam}
                                            />

                                        </div>
                                    </div>
                                </div>

                                <div className={(title === 'Team Member Association') ? "col-lg-12" : "col-lg-12 d-none "}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Team Member Association</h4>
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#TeamAssociation" data-bs-whatever="@mdo" onClick={handleAdd}>Add<i className="fas fa-plus"></i></button>



                                                <div className="modal fade" id="TeamAssociation" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered TeamAssociation-modal">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">{(values.id !== '') ? 'Update Team' : 'Add Team'} Member Association</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-lg-4 mt-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Team</label>
                                                                                <ReactSelect
                                                                                    options={TeamSelect(teamList)}
                                                                                    closeMenuOnSelect={true}
                                                                                    hideSelectedOptions={false}
                                                                                    name="team_id"
                                                                                    isClearable
                                                                                    value={values.team ?? showAllOption}
                                                                                    onChange={onTeamChange}
                                                                                    isDisabled={(values.id !== '') ? true : false}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 mt-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Member</label>
                                                                                <ReactSelect
                                                                                    options={MembersSelect(memberList)}
                                                                                    closeMenuOnSelect={true}
                                                                                    hideSelectedOptions={false}
                                                                                    name="member_id"
                                                                                    isClearable
                                                                                    value={values.member ?? showAllOptionMemeber}
                                                                                    onChange={onTeamChange}
                                                                                    isDisabled={(values.id !== '') ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-4 mt-3">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="recipient-name" className="col-form-label">Role</label>

                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" id="flexRadioDefault1" name='role' value='Administrator' onChange={handleChange} checked={(values.role === 'Administrator') ? true : false} />
                                                                                    <label className="form-check-label mb-0" htmlFor="flexRadioDefault1">
                                                                                        Administrator
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="radio" id="flexRadioDefault2" name='role' value='Member' onChange={handleChange} checked={(values.role === 'Member') ? true : false} />
                                                                                    <label className="form-check-label mb-0" htmlFor="flexRadioDefault2">
                                                                                        Member
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </form>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-primary" data-bs-dismiss={(values.role !== '') ? "modal" : ""} onClick={() => handleAssociationSubmit(values)} >{(values.id !== '') ? 'Update Member' : 'Add Member'} </button>
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                            <div className="table-responsive">
                                                <AssociationTable
                                                    data={associationList}
                                                    handleDeleteAssociation={handleDeleteAssociation}
                                                    teamList={teamList}
                                                    memberList={memberList}
                                                    handleEditTeam={handleEditTeam}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default ProjectCollaborator