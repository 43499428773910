import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar'
import Apis from '../../../../../api';
import Ppcgraph from '../../../Analytics/helpers/Ppcgraph';
import Reasongraph from '../../../Analytics/helpers/Reasongraph';
import IncidentReliabilityGraph from '../IncidentReliabilityGraph'
import io from 'socket.io-client'
import { months } from 'moment/moment';
import { Label } from 'reactstrap'
import { Input } from 'reactstrap'
const config = require('../../../../../config')
const LegendWrapperStyle = { lineHeight: 4 }
const indexName = {
    reliability_0: "Below MCOS Target",
    reliability_1: "Achieved MCOS Target"
}
const socket = io(config.api_url + '/')


const countMonthData = (year, month, commit, show_issue_list) => {
    var tempData = {
        no_incident: 0,
        incident: 0,
        count: 0,
        weekendsCount: 0,
    }


    for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
        const currentDate = new Date(year, month, day);
        const dayOfWeek = currentDate.getDay();
        var date = new Date(new Date(currentDate).setHours(0, 0, 0, 0)).getTime()
        tempData.count++
        // Check if it's Saturday (6) or Sunday (0)
        if (dayOfWeek === 6 || dayOfWeek === 0) {
            tempData.weekendsCount++
        } else {
            const index = commit.findIndex(obj => new Date(new Date(obj.date).setHours(0, 0, 0, 0)).getTime() === date);
            if (index !== -1) {
                const filterdata = commit[index]
                if (filterdata) {
                    if (filterdata.avg <= show_issue_list) {
                        tempData.incident++
                    } else {
                        tempData.no_incident++
                    }
                } else {
                    tempData.incident++
                }
            } else {
                tempData.incident++
            }

        }
    }

    return tempData;

}


const DetailGraph = ({ title, toDate, teamId, slug, teams }) => {

    const [graphData, setGraphData] = React.useState([])
    const [to_Dates, setToDates] = React.useState()
    const [from_Date, setFromDates] = React.useState()
    const [show_issue_list, setShowIssueList] = React.useState(0);
    const [PPCGraphData, setPPCGraphData] = React.useState([]);
    const [apiRespose, setApiRespose] = React.useState([]);
    const [show_loader, setLoaderShow] = React.useState(false);
    const [toolbarState, setToolbarState] = React.useState({ trend_line: true, min_max_line: true, mcos_line: false })

    const [safetyDataCount, setSafetyCount] = React.useState({
        weekend: 0,
        incident: 0,
        no_incident: 0,
        total_plan: 0,
    });

    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const title_data = data.find(setting => setting.key === slug);
                const graph = title_data ? parseInt(title_data.value) : 0;
                setShowIssueList(graph);
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [slug])


    React.useEffect(() => {
        const year = toDate.getFullYear();
        const fromDate = new Date(year, 0, 1);
        const toDates = new Date(year, 12, 0);
        setToDates(toDates)
        setFromDates(fromDate)
        let message = "hello";
        setApiRespose([]);
        setLoaderShow(true)
        setTimeout(() => {
            for (let i = 0; i <= 11; i++) {

                const filter = [{ is_active: false }]
                const date = new Date(year, i, i + 1)
                const from_Date = new Date(year, i, 1);
                const to_Dates = new Date(year, i + 1, 0);
                const month = date.toLocaleString('default', { month: 'long' })
                if (teamId) {
                    const demo = { "team_id": teamId.value }
                    filter.push(demo)
                }
                filter.push({ closing_date: { $gte: new Date(from_Date).getTime() } },
                    { closing_date: { $lte: new Date(to_Dates).getTime() } })

                const raw = {
                    "filter": filter,
                    "month": month,
                }

                socket.emit("task-commitment-reliability-filters", { message, raw });

            }
        }, 1000)



    }, [toDate, teamId])

    React.useEffect(() => {

        const handleData = (data) => {
            const { graphData, month } = data.data;
            var countData = apiRespose
            countData.push(month)

            setPPCGraphData((prev) => ({
                ...prev,
                [month]: graphData,
            }));


            setApiRespose(countData);


        };

        socket.on("task-commitment-reliability-filters-data", handleData);
        return () => socket.off("task-commitment-reliability-filters-data", handleData);
    }, []);




    React.useEffect(() => {
        const tempData = [];
        const year = toDate.getFullYear();
        const current_date = new Date();
        const current_year = current_date.getFullYear();
        const current_month = current_date.getMonth()

        for (let i = 0; i <= 11; i++) {
            var demo = ''
            const date = new Date(year, i, i + 1)
            const month = date.toLocaleString('default', { month: 'long' })
            var reliability_data = PPCGraphData[month] ? PPCGraphData[month] : [];
            var mcos_data = countMonthData(year, i, reliability_data, show_issue_list)

            if (current_year <= year) {
                if (i <= current_month) {
                    demo = {
                        name: date.toLocaleString('default', { month: 'short' }),
                        long: date.toLocaleString('default', { month: 'long' }),
                        incident: mcos_data.incident,
                        no_incident: mcos_data.no_incident,
                        dayInMonth: mcos_data.count,
                        weekend: mcos_data.weekendsCount
                    }
                } else {
                    demo = {
                        name: date.toLocaleString('default', { month: 'short' }),
                        long: date.toLocaleString('default', { month: 'long' }),
                        incident: 0,
                        no_incident: 0,
                        dayInMonth: mcos_data.count,
                        weekend: mcos_data.weekendsCount
                    }
                }

            } else {
                demo = {
                    name: date.toLocaleString('default', { month: 'short' }),
                    long: date.toLocaleString('default', { month: 'long' }),
                    incident: mcos_data.incident,
                    no_incident: mcos_data.no_incident,
                    dayInMonth: mcos_data.count,
                    weekend: mcos_data.weekendsCount
                }
            }
            tempData.push(demo)
        }

        setGraphData(tempData)

    }, [toDate, show_issue_list, PPCGraphData])

    React.useEffect(() => {
        const length = apiRespose.length
        if (length % 12 == 0) {
            setLoaderShow(false)
        } else {
            setLoaderShow(true)
        }

    }, [graphData, apiRespose])

    React.useEffect(() => {
        var total_incident = 0
        var total_no_incident = 0
        var total_weekend = 0
        graphData && graphData.map((elm) => {
            total_incident += parseInt(elm.incident);
            total_no_incident += parseInt(elm.no_incident);
            total_weekend += parseInt(elm.weekend);
            return true;

        })
        setSafetyCount({
            weekend: total_weekend,
            incident: total_incident,
            no_incident: total_no_incident,
            total_plan: parseInt(total_incident) + parseInt(total_no_incident)
        })
    }, [graphData])

    return (
        <>
            <Backdrop
                sx={{ bgcolor: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={show_loader}
            />
            <Snackbar
                open={show_loader}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#308fe8',

                    }}
                    size={30}
                    thickness={4}
                />
            </Snackbar>
            <div className="row">
                <div className="col-lg-4">
                    <IncidentReliabilityGraph
                        title={slug}
                        toDate={toDate}
                        teamId={teamId}
                        show_link={false}
                    />
                </div>
                <div className="col-lg-8">
                    <div className="card">
                        <h4 className="card-footer text-center fs-16 border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            {title + ' Count Bar Graph'}
                        </h4>
                        <div className="card-body" style={{ height: '595px' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={graphData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >

                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip
                                        formatter={(value, name) => [value ? `${Math.round(value)}` : `0`, name === 'no_incident' ? indexName[slug + '_1'] : indexName[slug + '_0']]}
                                    />
                                    <Legend
                                        wrapperStyle={LegendWrapperStyle}
                                        verticalAlign="top"
                                        formatter={(value) => value === 'no_incident' ? indexName[slug + '_1'] : indexName[slug + '_0']}
                                    />
                                    <Bar dataKey="no_incident" fill="#6fc764" animationEasing="linear" />
                                    <Bar dataKey="incident" fill="red" animationEasing="linear" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                    </div>
                </div>
                <div className="col-lg-12 ">
                    <div className="card">
                        <div className="card-footer text-center fs-3 fw-bolder border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            {title + ' Calender View'}
                        </div>
                        <div className="card-body">
                            <table className='table table-striped' cellPadding="5" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <th className='text-center'>{elm.long}</th>
                                                </>
                                            )
                                        })}
                                        <th className='text-center'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{indexName[slug + '_0']}</td>
                                        <td className='incident_log'><div></div></td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.incident}</td>
                                                </>
                                            )
                                        })}

                                        <td className='text-center'>{safetyDataCount && safetyDataCount.incident}</td>
                                    </tr>
                                    <tr>
                                        <td>{indexName[slug + '_1']}</td>
                                        <td className='no_incident_log'><div></div></td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.no_incident}</td>
                                                </>
                                            )
                                        })}
                                        <td className='text-center'>{safetyDataCount && safetyDataCount.no_incident}</td>
                                    </tr>
                                    <tr>
                                        <td>Holiday/Weekend</td>
                                        <td className='hoilday_log'><div></div></td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.weekend}</td>
                                                </>
                                            )
                                        })}
                                        <td className='text-center'>{safetyDataCount && safetyDataCount.weekend}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Plans</td>
                                        <td ></td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.no_incident + elm.incident}</td>

                                                </>
                                            )
                                        })}
                                        <td className='text-center'>{safetyDataCount && safetyDataCount.total_plan}</td>
                                    </tr>
                                    <tr>
                                        <td>Days in the month</td>
                                        <td></td>
                                        {graphData && graphData.map((elm) => {
                                            return (
                                                <>
                                                    <td className='text-center'>{elm.dayInMonth}</td>
                                                </>
                                            )
                                        })}
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-footer text-center fs-3 fw-bolder border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            Reliability Report (PPC)
                        </div>
                        <div className="card-body">
                            <div className='container-fluid'>
                                <div className="row justify-content-center">
                                    <div className="col-lg-2">
                                        <Label for="analytics-filter-panel-to-date" >
                                            Show Trend Line
                                        </Label>
                                        <div className="col-lg-2 d-flex justify-content-end align-items-center p-0">
                                            <input style={{ padding: '10px' }} className="form-check-input" type="checkbox" name="show_trendline" onChange={() =>
                                                setToolbarState((prev) => ({ ...(prev ?? {}), ['trend_line']: !toolbarState.trend_line }))
                                            } checked={toolbarState.trend_line} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <Label for="analytics-filter-panel-to-date" >
                                            Show Min & Max
                                        </Label>
                                        <div className="col-lg-3 d-flex justify-content-end align-items-center p-0">
                                            <input style={{ padding: '10px' }} className="form-check-input" type="checkbox" name="show_min_max" onChange={() =>
                                                setToolbarState((prev) => ({ ...(prev ?? {}), ['min_max_line']: !toolbarState.min_max_line }))
                                            } checked={toolbarState.min_max_line} />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <Label for="analytics-filter-panel-to-date">
                                            Show MCOS
                                        </Label>
                                        <div className="col-lg-3 d-flex justify-content-end align-items-center p-0">
                                            <input style={{ padding: '10px' }}
                                                className="form-check-input" type="checkbox" name="show_min_max"
                                                onChange={() =>
                                                    setToolbarState((prev) => ({ ...(prev ?? {}), ['mcos_line']: !toolbarState.mcos_line }))
                                                } checked={toolbarState.mcos_line} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Ppcgraph
                                fromDate={from_Date}
                                toDate={to_Dates}
                                teamId={teamId ? teamId.value : '$$all'}
                                teams={teams}
                                showTrendLine={toolbarState.trend_line}
                                showMinMAxLine={toolbarState.min_max_line}
                                showMcosLine={toolbarState.mcos_line}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-footer text-center fs-3 fw-bolder border-bottom mt-2" style={{ backgroundColor: '#ffffff' }}>
                            Reasons Report
                        </div>
                        <div className="card-body">
                            <Reasongraph
                                fromDate={from_Date}
                                toDate={to_Dates}
                                teamId={teamId ? teamId.value : '$$all'}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailGraph;