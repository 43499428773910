import React from 'react';
import Apis from '../../../../api';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const DashboardData = () => {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })

    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    const [dashboard_data, setDashboardData] = React.useState({
        safety: 'manual',
        quality: 'manual',
        cost: 'manual',
        reliability: 0,
        people_mcos: 0,
        delivery_mcos: 0,
        people: 'manual',
        delivery: 'manual',
    })

    const [setting_data, setSettingData] = React.useState([])
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            setSettingData(data);
            if (data.length > 0) {
                const safety = data.find(setting => setting.key === 'safety');
                const quality = data.find(setting => setting.key === 'quality');
                const cost = data.find(setting => setting.key === 'cost');
                const reliability = data.find(setting => setting.key === 'reliability');
                const people = data.find(setting => setting.key === 'people');
                const people_mcos = data.find(setting => setting.key === 'people_mcos');
                const delivery_mcos = data.find(setting => setting.key === 'delivery_mcos');
                const delivery = data.find(setting => setting.key === 'delivery');
                setDashboardData({
                    ...dashboard_data,
                    safety: safety ? safety.value : 'manual',
                    quality: quality ? quality.value : 'manual',
                    cost: cost ? cost.value : 'manual',
                    reliability: reliability ? reliability.value : 0,
                    people_mcos: people_mcos ? people_mcos.value : 0,
                    delivery_mcos: delivery_mcos ? delivery_mcos.value : 0,
                    people: people ? people.value : 'manual',
                    delivery: delivery ? delivery.value : 'manual'
                })
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [refresh]);

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        const safety = setting_data.find(setting => setting.key === name);
        if (name === 'reliability') {
            if (value < 0 || value > 100) {
                setError({
                    ...error,
                    error: 'Reliability between 1 to 100.',
                    color: 'error',
                    open: true
                })
                return false;
            }
        }
        if (name === 'people_mcos') {
            if (value < 0 || value > 100) {
                setError({
                    ...error,
                    error: 'People between 1 to 100.',
                    color: 'error',
                    open: true
                })
                return false;
            }
        }
        if (name === 'delivery_mcos') {
            if (value < 0 || value > 100) {
                setError({
                    ...error,
                    error: 'Delivery between 1 to 100.',
                    color: 'error',
                    open: true
                })
                return false;
            }
        }
        const raw = {
            'key': name,
            'value': value
        }
        if (safety) {
            Apis.updateCalender(raw).then((res) => {
                setRefresh(!refresh)
                setError({
                    ...error,
                    error: 'Dashboard Data updated',
                    color: 'success',
                    open: true
                })
            }).catch((error) => {
                console.log(error)
            });
        } else {

            Apis.addSetting(raw).then((res) => {
                setRefresh(!refresh)
                setError({
                    ...error,
                    error: 'Dashboard Data updated',
                    color: 'success',
                    open: true
                })

            }).catch((error) => {
                console.log(error)
            });
        }

    }
    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={() => setError({
                    ...error,
                    open: false,
                })}
            >
                <Alert
                    onClose={() => setError({
                        ...error,
                        open: false,
                    })}
                    severity={error.color}
                    sx={{ width: '100%' }}>
                    {error.error}
                </Alert>
            </Snackbar>
            <div className=" shadow-none card">
                <div className='card-block'>
                    <div className="account-box">
                        <div className="card-box shadow-none p-4">
                            <div className="p-4">
                                <div className="form-group mb-3">
                                    <h5 className="form-label">Dashboard Data</h5>
                                </div>
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <th >Safety</th>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="safety"
                                                        id="safety_auto"
                                                        value='automatic'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.safety === 'automatic' ? true : false} />
                                                    <label className="form-check-label" for="safety_auto">
                                                        Action/Issue Log
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="safety"
                                                        id="safety_manual"
                                                        value='manual'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.safety === 'manual' ? true : false} />
                                                    <label className="form-check-label" for="safety_manual">
                                                        Report Only
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Quality</th>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="quality"
                                                        id="quality_auto"
                                                        value='automatic'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.quality === 'automatic' ? true : false} />
                                                    <label className="form-check-label" for="quality_auto">
                                                        Action/Issue Log
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="quality"
                                                        id="quality_manual"
                                                        value='manual'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.quality === 'manual' ? true : false} />
                                                    <label className="form-check-label" for="quality_manual">
                                                        Report Only
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Cost</th>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="cost"
                                                        id="cost_auto"
                                                        value='automatic'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.cost === 'automatic' ? true : false} />
                                                    <label className="form-check-label" for="cost_auto">
                                                        Action/Issue Log
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="cost"
                                                        id="cost_manual"
                                                        value='manual'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.cost === 'manual' ? true : false} />
                                                    <label className="form-check-label" for="cost_manual">
                                                        Report Only
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <label className="form-check-label" for="reliability_auto">
                                                    Reliability (MCOS)
                                                </label>
                                            </th>
                                            <td colSpan={2}>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="reliability"
                                                        id="reliability_auto"
                                                        value={dashboard_data.reliability}
                                                        onChange={handleChnage}
                                                    />
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <th>People</th>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="people"
                                                        id="people_auto"
                                                        value='automatic'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.people === 'automatic' ? true : false} />
                                                    <label className="form-check-label" for="people_auto">
                                                        Action/Issue Log
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="people"
                                                        id="people_manual"
                                                        value='manual'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.people === 'manual' ? true : false} />
                                                    <label className="form-check-label" for="people_manual">
                                                        Report Only
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <label className="form-check-label" for="people_mcos">
                                                    People (MCOS)
                                                </label>
                                            </th>
                                            <td colSpan={2}>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="people_mcos"
                                                        id="people_mcos"
                                                        value={dashboard_data.people_mcos}
                                                        onChange={handleChnage}
                                                    />
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <th>Delivery</th>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="delivery"
                                                        id="delivery_auto"
                                                        value='automatic'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.delivery === 'automatic' ? true : false} />
                                                    <label className="form-check-label" for="delivery_auto">
                                                        Action/Issue Log
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="delivery"
                                                        id="delivery_manual"
                                                        value='manual'
                                                        onChange={handleChnage}
                                                        checked={dashboard_data.delivery === 'manual' ? true : false} />
                                                    <label className="form-check-label" for="delivery_manual">
                                                        Report Only
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <label className="form-check-label" for="delivery_mcos">
                                                    Delivery (MCOS)
                                                </label>
                                            </th>
                                            <td colSpan={2}>
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="delivery_mcos"
                                                        id="delivery_mcos"
                                                        value={dashboard_data.delivery_mcos}
                                                        onChange={handleChnage}
                                                    />
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardData;