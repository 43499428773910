import { DateHelper, StringHelper, TaskModel } from '@bryntum/gantt';
import Button from './helpers/Button';
import Radio from "./helpers/Radio";
import Select from "./helpers/Select";
import Input from "./helpers/Input";
import './helpers/GanttToolbar';

class MyTask extends TaskModel {
    static get fields() {
        return [
            'hoursWorked'
        ];
    }

    get workedHoursByDay() {
        const
            me = this,

            { startDate, endDate, isParent, duration } = me;


        // With Gantt using early rendering we can get here before dates are normalized, if so bail out to not cache
        // invalid values below
        if (!startDate || !endDate || !duration) {
            return null;
        }

        if (!isParent && me._workedHoursByDay?.duration === duration && me._workedHoursByDay.startDateMs === startDate.getTime()) {
            return me._workedHoursByDay;
        }

        const
            durationInDays = DateHelper.getDurationInUnit(startDate, endDate, 'd', false),

            workedHoursByDay = Array(durationInDays || 0).fill(0),
            calendar = me.project.calendar,
            hoursWorked = me.hoursWorked || [];


        let index = 0;

        // Rollup values from parent task's immediate children
        for (let i = 0; i < durationInDays; i++) {
            const
                intervalStart = DateHelper.add(startDate, i, 'd'),
                intervalEnd = DateHelper.add(intervalStart, 1, 'd');

            if (calendar.isWorkingTime(intervalStart, intervalEnd)) {
                if (isParent) {
                    workedHoursByDay[i] = this.children.reduce((total, child) => {

                        if (DateHelper.intersectSpans(child.startDate, child.endDate, intervalStart, intervalEnd)) {
                            const startDiff = DateHelper.getDurationInUnit(startDate, child.startDate, 'd');
                            return total += child.workedHoursByDay[i - startDiff];
                        }
                        else {
                            return total;
                        }
                    }, 0);
                }
                else {
                    workedHoursByDay[i] = hoursWorked[index];

                }
                index++;
            }
        }

        // Cache by start + duration
        workedHoursByDay.startDateMs = startDate.getTime();
        workedHoursByDay.duration = duration;

        me._workedHoursByDay = workedHoursByDay;

        return workedHoursByDay;
    }
}

export const projectConfig = {
    calendar: 'general',
    startDate: DateHelper.add(DateHelper.clearTime(new Date()), -200, 'day'),
    endDate: DateHelper.add(DateHelper.clearTime(new Date()), +200, 'day'),
    hoursPerDay: 24,
    daysPerWeek: 5,
    daysPerMonth: 20,
    autoLoad: true,
    monitorResize: true,
    stm: {
        autoRecord: true
    },
    taskModelClass: MyTask,
    taskStore: {
        sorters: [{ field: 'startdate', ascending: false }]
    },
    skipNonWorkingTimeInDurationWhenSchedulingManually: true,
   

};

export const resourceProjectConfig = {
    calendar: 'general',
    startDate: DateHelper.add(DateHelper.clearTime(new Date()), -200, 'day'),
    endDate: DateHelper.add(DateHelper.clearTime(new Date()), +200, 'day'),
    hoursPerDay: 24,
    daysPerWeek: 5,
    daysPerMonth: 20,
    autoLoad: true,
    monitorResize: true,
    stm: {
        autoRecord: true
    },
    taskModelClass: MyTask,
    skipNonWorkingTimeInDurationWhenSchedulingManually: true,
    
};

export const ganttConfig = {
    tbar: { type: 'gantttoolbars' },
    visibleDate: {
        date: new Date(),
        block: 'center'
    },
    subGridConfigs: {
        locked: {
            flex: 6
        },
        normal: {
            flex: 3
        }
    },
    columnLines: true,
    viewPreset: {
        base: 'weekAndDay',
        tickWidth: 50,
        tickHeight: 50,
        shiftIncrement: 1,
        shiftUnit: 'day',

        timeResolution: {
            unit: 'day',
            increment: 1
        },
        headers: [
            {
                unit: 'month',
                dateFormat: 'MMM YYYY',
                align: 'center'
            },
            {
                unit: 'week',
                renderer: (startDate, endDate) => `${DateHelper.format(startDate, 'DD MMM')} - ${DateHelper.format(endDate, 'DD MMM')} (Week ${DateHelper.format(startDate, 'WW')})`
            },
            {
                unit: 'day',
                dateFormat: 'ddd, D'
            }
        ],

    },
    timeRangesFeature: {
        showHeaderElements: false,
        showCurrentTimeLine: true
    },
    timeAxisHeaderMenuFeature: {
        items: {
            dateRange: false,
            nameFilter: false,
            currentTimeLine: false,
            eventsFilter: false,
        }
    },
    taskTooltipFeature: {
        disabled: true
    },
    taskMenuFeature: false,
    rollupsFeature: {
        disabled: true
    },
    baselinesFeature: {
        disabled: true
    },
    progressLineFeature: {
        disabled: true,
    },
    filterFeature: true,
    taskDragFeature: {
        disabled: true
    },
    dependencyEditFeature: false,
    projectLinesFeature: {
        disabled: true
    },
    headerMenuFeature: {
        disabled: true
    },
    taskEditFeature: {
        disabled: true
    },
    percentBarFeature: false,
    columns: [
        { type: 'name', width: 400, text: 'Task name', editor: false, sortable: false, filterable: false },
        {
            editor: false, text: 'Status', width: 130, sortable: false,
            renderer: ({ record }) =>
                record.type === 'Task' ? record.status_code[0].label : record.type === 'Milestone' ? record.status_code[0].label : null
        },
        { type: 'duration', width: 130, text: 'Duration', editor: false, sortable: false, filterable: false },
        {
            text: "Team", width: 130, editor: false, sortable: false, filterable: false,
            renderer: ({ record }) => {
                return <>{record.team.label} </>

            }
        },
        {
            text: "Member", width: 130, editor: false, sortable: false, filterable: false, renderer: ({ record }) => {
                return <>{record.member.label} </>

            }
        },
        {
            editor: false, width: 130, text: 'On Plan', htmlEncodeHeaderText: false, align: 'center', sortable: false, filterable: false,
            renderer: ({ record, grid, grid: { extraData } }) =>

                record.type === "Task" && record.user_role !== 'member' ?
                    <Button
                        id={record.id}
                        taskData={extraData.taskDep}
                        status={record.status_code[0].value}
                        type={record.type}
                        onClick={extraData.change}
                        markedAsCommit={record.markedAsCommit}
                    />
                    : <></>


        },
    ],
    taskRenderer({ taskRecord }) {

        const getBusinessDatesCount = (startDate, endDate) => {
            let count = 0;
            let curDate = startDate;
            while (curDate < endDate) {
                const dayOfWeek = new Date(curDate).getDay();
                const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
                if (!isWeekend) {
                    count++;
                }
                curDate = curDate + 24 * 60 * 60 * 1000
            }

            return count;
        }
        let difference = 0
        if (!taskRecord.isMilestone) {
            if (taskRecord.type === 'Task') {
                const complition_date = taskRecord.taskrefdate
                const endDate = new Date(taskRecord.endDate).getTime()
                if (complition_date === 0) {
                    return [
                        {
                            tag: 'div',
                            class: 'taskName',
                            html: StringHelper.encodeHtml(taskRecord.name)
                        }
                    ]
                }
                if (complition_date < endDate) {
                    difference = getBusinessDatesCount(complition_date, endDate)
                    if (difference <= 0) {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            }
                        ]
                    } else {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            },
                            {
                                tag: 'div',
                                class: 'gantt_task_delta_marker',
                                text: "-" + difference,
                                role: 'presentation'
                            }
                        ]
                    }

                } else {
                    difference = getBusinessDatesCount(endDate, complition_date)
                    if (difference <= 0) {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            }
                        ]
                    } else {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            },
                            {
                                tag: 'div',
                                class: 'gantt_task_delta_marker',
                                text: difference,
                                role: 'presentation'
                            }
                        ]
                    }

                }

            } else {
                return [
                    {
                        tag: 'div',
                        class: 'taskName',
                        html: StringHelper.encodeHtml(taskRecord.name)
                    }
                ]
            }

        } else {
            const complition_date = taskRecord.taskrefdate
            const endDate = new Date(taskRecord.endDate).getTime()
            if (complition_date < endDate) {
                difference = getBusinessDatesCount(complition_date, endDate)
                if (difference <= 0) {
                    return []
                } else {
                    return [
                        {
                            tag: 'div',
                            class: 'gantt_task_delta_marker_milestone',
                            text: "-" + difference,
                            role: 'presentation'
                        }
                    ]
                }

            } else {
                difference = getBusinessDatesCount(endDate, complition_date)
                if (difference <= 0) {
                    return []
                } else {
                    return [
                        {
                            tag: 'div',
                            class: 'gantt_task_delta_marker_milestone',
                            text: difference,
                            role: 'presentation'
                        }
                    ]
                }

            }
        }
    },

};

export const ganttCloseConfig = {
    tbar: { type: 'gantttoolbars' },
    visibleDate: {
        date: new Date(),
        block: 'center'
    },
    subGridConfigs: {
        locked: {
            flex: 6
        },
        normal: {
            flex: 3
        }
    },
    columnLines: true,
    viewPreset: {
        base: 'weekAndDay',
        tickWidth: 50,
        tickHeight: 50,
        shiftIncrement: 1,
        shiftUnit: 'day',

        timeResolution: {
            unit: 'day',
            increment: 1
        },
        headers: [
            {
                unit: 'month',
                dateFormat: 'MMM YYYY',
                align: 'center'
            },
            {
                unit: 'week',
                renderer: (startDate, endDate) => `${DateHelper.format(startDate, 'DD MMM')} - ${DateHelper.format(endDate, 'DD MMM')} (Week ${DateHelper.format(startDate, 'WW')})`
            },
            {
                unit: 'day',
                dateFormat: 'ddd, D'
            }
        ],

    },
    timeRangesFeature: {
        showHeaderElements: false,
        showCurrentTimeLine: true
    },
    timeAxisHeaderMenuFeature: {
        items: {
            dateRange: false,
            nameFilter: false,
            currentTimeLine: false,
            eventsFilter: false,
        }
    },
    taskTooltipFeature: {
        disabled: true
    },
    taskMenuFeature: false,
    rollupsFeature: {
        disabled: true
    },
    baselinesFeature: {
        disabled: true
    },
    progressLineFeature: {
        disabled: true,
    },
    filterFeature: true,
    taskDragFeature: {
        disabled: true
    },
    dependencyEditFeature: false,
    projectLinesFeature: {
        disabled: true
    },
    headerMenuFeature: {
        disabled: true
    },
    taskEditFeature: {
        disabled: true
    },
    percentBarFeature: false,
    columns: [
        { type: 'name', width: 250, text: 'Task name', editor: false, sortable: false, filterable: false },
        { type: 'duration', width: 100, text: 'Duration', editor: false, sortable: false, filterable: false },
        {
            text: "Team", width: 130, editor: false, sortable: false, filterable: false,
            renderer: ({ record }) => {
                return <>{record.team.label} </>

            }
        },
        {
            text: "Member", width: 130, editor: false, sortable: false, filterable: false, renderer: ({ record }) => {
                return <>{record.member.label} </>

            }
        },
        {
            editor: false, text: 'Task Completed', htmlEncodeHeaderText: false, align: 'center', sortable: false, filterable: false,
            renderer: ({ record, grid, grid: { extraData } }) =>
                record.type === 'Task' && record.user_role !== 'member' ? (
                    <>
                        <Radio
                            id={record.id}
                            commit_id={record.commit_id}
                            onClick={extraData.select}
                            markedAsCommit={record.markedAsCommit}
                            markedAsUnCommit={record.markedAsUnCommit}
                            status={record.old_status_code[0].value}
                            taskData={extraData.taskDep}
                            reason_code_id={record.reason_code_id}
                        />
                    </>
                ) : (<></>)



        },
        {
            editor: false, text: 'Reason Code', htmlEncodeHeaderText: false, align: 'center', sortable: false, filterable: false,
            renderer: ({ record, grid, grid: { extraData } }) =>
                record.type === 'Task' ? (
                    <>
                        <Select
                            id={record.id}
                            commit_id={record.commit_id}
                            updateTask={extraData.updateTask}
                            markedAsCommit={record.markedAsCommit}
                            markedAsUnCommit={record.markedAsUnCommit}
                            reason_code_id={record.reason_code_id}
                            old_status={record.old_status_code[0].value}
                        />
                    </>
                ) : (<></>)
        },
        {
            editor: false, text: 'Root Cause Analysis', htmlEncodeHeaderText: false, align: 'center', sortable: false, filterable: false,
            renderer: ({ record, grid, grid: { extraData } }) =>
                record.type === 'Task' ? (
                    <>
                        <Input
                            id={record.id}
                            commit_id={record.commit_id}
                            updateTask={extraData.updateTask}
                            markedAsCommit={record.markedAsCommit}
                            markedAsUnCommit={record.markedAsUnCommit}
                            reason_code_id={record.reason_code_id}
                            reason_description={record.reason_description}
                            old_status={record.old_status_code[0].value}
                        />
                    </>
                ) : (<></>)

        }
    ],
    taskRenderer({ taskRecord }) {
        if (!taskRecord.isMilestone) {
            if (taskRecord.type === 'Task') {
                let difference = 0
                const getBusinessDatesCount = (startDate, endDate) => {
                    let count = 0;
                    let curDate = startDate;
                    while (curDate < endDate) {
                        const dayOfWeek = new Date(curDate).getDay();
                        const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
                        if (!isWeekend) {
                            count++;
                        }
                        curDate = curDate + 24 * 60 * 60 * 1000
                    }

                    return count;
                }
                const complition_date = taskRecord.taskrefdate
                const endDate = new Date(taskRecord.endDate).getTime()
                if (complition_date < endDate) {
                    difference = getBusinessDatesCount(complition_date, endDate)
                    if (difference <= 0) {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            }
                        ]
                    } else {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            },
                            {
                                tag: 'div',
                                class: 'gantt_task_delta_marker',
                                text: "-" + difference,
                                role: 'presentation'
                            }
                        ]
                    }

                } else {
                    difference = getBusinessDatesCount(endDate, complition_date)
                    if (difference <= 0) {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            }
                        ]
                    } else {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            },
                            {
                                tag: 'div',
                                class: 'gantt_task_delta_marker',
                                text: difference,
                                role: 'presentation'
                            }
                        ]
                    }

                }

            } else {
                return [
                    {
                        tag: 'div',
                        class: 'taskName',
                        html: StringHelper.encodeHtml(taskRecord.name)
                    }
                ]
            }

        }
    },

};