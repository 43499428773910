import React, { useState, useRef, useEffect } from 'react'
import {
    BryntumGantt,
    BryntumGanttProjectModel
} from '@bryntum/gantt-react'
import { ganttConfig, projectConfig } from './GanttConfig'


const GanttComponts = ({ taskData, calanderData }) => {
    const resources = [];
    const dependencies = [];
    const assignments = [];


    const [tasks, setTasks] = useState([])
    const [ganttConfigs, setConfig] = useState(ganttConfig)

    const gantt = useRef();
    const project = useRef();
    const [calendars, setCalendars] = useState([{
        id: 'general',
        name: 'General',
        intervals: [
            {
                recurrentStartDate: 'on Sat at 0:00',
                recurrentEndDate: 'on Mon at 0:00',
                isWorking: false
            }
        ],
        expanded: true,
    }]);

    useEffect(() => {
        const calendars = [{
            id: 'general',
            name: 'General',
            intervals: [
                {
                    recurrentStartDate: 'on Sat at 0:00',
                    recurrentEndDate: 'on Mon at 0:00',
                    isWorking: false
                }
            ],
            expanded: true,
        }];
        var projectCalendarValues = calanderData.projectCalendarValues
        if (projectCalendarValues.length !== 0) {
            projectCalendarValues.map((elm) => {
                let startDate = new Date(new Date(elm.date).setHours(0, 0, 0, 0)).getTime();
                let endDate = new Date(new Date(elm.date).setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000).getTime();
                var demo = {
                    endDate: new Date(endDate),
                    startDate: new Date(startDate),
                    isWorking: elm.isWorkingDay
                }
                calendars[0].intervals.push(demo)

            })
        }

        setCalendars(calendars)

    }, [calanderData.projectCalendarValues])

    const getBusinessDatesCount = (startDate, endDate) => {
        let count = 0;
        let curDate = startDate;
        while (curDate < endDate) {
            const dayOfWeek = new Date(curDate).getDay();
            const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
            if (!isWeekend) {
                count++;
            }
            curDate = curDate + 24 * 60 * 60 * 1000
        }

        return count;
    }

    useEffect(() => {
        setTasks([])
        if (taskData.length !== 0) {

            setTimeout(() => {
                let list = []
                let demo = null
                let projects = taskData.project
                let milestones = taskData.milestones
                let tasks = taskData.task
                let difference = 0
                projects.map((project) => {
                    demo = {
                        id: project._id,
                        type: 'Project',
                        name: project.name,
                        task_start_date: new Date(project.startDate).setHours(0, 0, 0, 0).toString(),
                        startDate: new Date(new Date(project.startDate).setHours(0, 0, 0, 0)).toString(),
                        expanded: false,
                        duration: 0,
                        manuallyScheduled: false,
                        eventColor: "#8ee997",
                        children: []
                    }
                    list.push(demo)
                    return list
                })
                projects.map((project, key) => {
                    milestones.map((milestone) => {
                        if (milestone.parent === project._id) {
                            tasks.map((task) => {
                                let day = new Date(task.startDate).getDay()
                                let startDate = new Date(new Date(task.startDate).setHours(0, 0, 0, 0))
                                if (day === 0) {
                                    startDate.setDate(startDate.getDay() + 0)
                                } else if (day === 6) {
                                    startDate.setDate(startDate.getDay() + 1)
                                }

                                if (task.parent === milestone._id) {
                                    if (milestone.complition_date < task.startDate) {
                                        difference = getBusinessDatesCount(milestone.complition_date, task.startDate)
                                    } else {
                                        difference = getBusinessDatesCount(task.startDate, milestone.complition_date)
                                    }

                                    demo = {
                                        id: task._id,
                                        type: 'Task',
                                        name: milestone.name + ' Planned',
                                        startDate: startDate,
                                        task_start_date: new Date(new Date(startDate).setHours(0, 0, 0, 0) + difference * 24 * 60 * 60 * 1000).getTime().toString(),

                                        duration: difference,
                                        expanded: false,
                                        manuallyScheduled: true,
                                        cls: 'important'
                                    }
                                    if (list[key].children.length === 0) {
                                        list[key].children.push(demo)
                                        list[key].startDate = startDate.toString()
                                        list[key].task_start_date = new Date(new Date(startDate).setHours(0, 0, 0, 0) + difference * 24 * 60 * 60 * 1000).getTime().toString()
                                    }
                                    if (milestone.startDate < milestone.complition_date) {
                                        difference = getBusinessDatesCount(milestone.startDate, milestone.complition_date)
                                    } else {
                                        difference = getBusinessDatesCount(milestone.complition_date, milestone.startDate)
                                    }

                                    demo = {
                                        id: milestone._id,
                                        type: 'Task',
                                        name: milestone.name + ' Actual',
                                        startDate: startDate,
                                        task_start_date: new Date(new Date(startDate).setHours(0, 0, 0, 0) + difference * 24 * 60 * 60 * 1000).getTime().toString(),
                                        duration: difference,
                                        expanded: false,
                                        manuallyScheduled: true,
                                        cls: 'important'
                                    }
                                    if (list[key].children.length === 1) {
                                        list[key].startDate = startDate.toString()
                                        list[key].task_start_date = new Date(new Date(startDate).setHours(0, 0, 0, 0) + difference * 24 * 60 * 60 * 1000).getTime().toString()
                                        list[key].children.push(demo)
                                    }
                                }
                                return list
                            })
                            return list
                        }
                        return list
                    })
                    return list
                })

                setTasks(list)
            }, 5)

        }

    }, [taskData])
    useEffect(() => {
        setConfig({
            ...ganttConfigs,
            sortFeature: {
                field: 'endDate'
            },
        })
    }, [taskData])
    return (
        <>
            <BryntumGanttProjectModel
                ref={project}
                {...projectConfig}
                tasks={tasks}
                calendars={calendars}
                assignments={assignments}
                dependencies={dependencies}
                resources={resources}
            />
            <BryntumGantt
                ref={gantt}
                {...ganttConfig}
                project={project}
            />
        </>
    )
}

export default GanttComponts