import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import { Link } from 'react-router-dom';
import Apis from '../../../../api';
import keyBy from 'lodash/keyBy';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, index } = props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    var color = 'white';
    if (props.color === '#dde1e5') {
        color = '#000000'
    }

    return (
        <text x={x} y={y} fill={color} textAnchor='middle' dominantBaseline="central">
            {`${index + 1}`}
        </text>
    );
};
const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    const arr = Array.from(payload.name);
    const name = arr[0];

    return (
        <g>
            <text className='text-uppercase' x={cx} y={215} dy={8} textAnchor="middle" fill="#000000" style={{ fontSize: '150px', fontWeight: '600' }}>
                {name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius}
                outerRadius={outerRadius}
                fill={fill}
            />


        </g>
    );
};

const ucfirst = (text) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
}

const indexName = {
    quality_0: "Defects",
    quality_1: "Defect Free",
    quality_2: "Days without defects",
    safety_0: "Incident",
    safety_1: "No Incident",
    safety_2: "Days without incidents",
    cost_0: "Above MCOS Target",
    cost_1: "Within MCOS Target",
    cost_2: "Days without MCOS Target",
    reliability_0: "Defects",
    reliability_1: "Defect Free",
    reliability_2: "Days without defects",
    people_0: "Defects",
    people_1: "Defect Free",
    people_2: "Days without defects",
    delivery_0: "Delays",
    delivery_1: "On Time Delivery",
    delivery_2: "Days without delays",
}

const IncidentGraph = ({ title, toDate, teamId, show_link = true, refresh = false }) => {
    const [graphData, setGraphData] = React.useState([])
    const [apiResponse, setapiResonse] = React.useState([]);
    const [issueResponse, setissueApiResonse] = React.useState([]);
    const [dashboardData, setDashboardData] = React.useState([]);
    const [workingDays, setWorkingDaya] = React.useState([])
    const [calendar, setCalendar] = React.useState([])
    const [show_issuce_list, setShowIssuceList] = React.useState();
    const [safetyDataCount, setSafetyCount] = React.useState({
        todayDays: 0,
        weekend: 0,
        workingDay: 0,
        incident: 0,
        no_incident: 0,
    });

    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const title_data = data.find(setting => setting.key === title);
                const graph = title_data ? title_data.value : 'manual';
                setShowIssuceList(graph);
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [title])

    React.useEffect(() => {
        const year = toDate.getFullYear();
        const month = toDate.getMonth();
        const fromDate = new Date(year, month, 1);
        const toDates = new Date(year, month + 1, 0);
        let filters = [
            { type: title }
        ]

        if (teamId) {
            const demo = { "team_id": teamId.value }
            filters.push(demo)
        }

        if (show_issuce_list === 'manual') {
            filters.push({ timeStamp: { $gte: new Date(fromDate).getTime() } },
                { timeStamp: { $lte: new Date(toDates).getTime() } })
            const raw = {
                "filter": filters
            }
            Apis.getDashboardData(raw).then((res) => {
                setapiResonse(res.data.data.dashboard_data)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            filters.push({ reported_date: { $gte: new Date(fromDate).getTime() } },
                { reported_date: { $lte: new Date(toDates).getTime() } })
            const raw = {
                "filter": filters
            }
            Apis.getDashboardIssue(raw).then((res) => {
                setissueApiResonse(res.data.data.dashboard_data)
            }).catch((error) => {
                console.log(error)
            })
        }

    }, [title, toDate, teamId, show_issuce_list, refresh])

    React.useEffect(() => {
        var tempData = []
        apiResponse && apiResponse.map((elm) => {
            const index = tempData.findIndex(obj => obj.timeStamp === elm.timeStamp);
            if (index === -1) {
                tempData.push(elm)
            } else {
                var value = elm.value
                var tempValue = tempData[index].value
                if (value.length !== 0 && tempValue.length !== 0) {
                    tempData[index].value = parseInt(tempValue) + parseInt(value)
                } else if (tempValue.length === 0 && value.length !== 0) {
                    tempData[index].value = value
                }

            }
        })
        setDashboardData(tempData)

    }, [apiResponse])

    React.useEffect(() => {
        var tempData = []
        issueResponse && issueResponse.map((elm) => {
            var startDate = new Date(new Date(elm.reported_date).setHours(0, 0, 0, 0)).getTime()
            const index = tempData.findIndex(obj => obj.timeStamp === startDate);
            if (index === -1) {
                var demo = {
                    _id: elm._id,
                    value: 1,
                    timeStamp: startDate,
                    team_id: elm.team_id,
                    type: elm.type
                }
                tempData.push(demo)
            } else {
                tempData[index].value = tempData[index].value + 1
            }
        })

        setDashboardData(tempData)

    }, [issueResponse])

    React.useEffect(() => {
        Apis.getCalendar().then((res) => {
            var calendarData = res.data.data
            if (calendarData.projectSetting !== undefined) {
                setCalendar(calendarData.projectSetting.value.dates)
            }
        }).catch(error => {
            console.log(error.response.data)
        })
    }, [])

    React.useEffect(() => {
        var tempWorkingDay = []
        calendar && calendar.map((elm) => {
            var demo = {
                timeStamp: new Date(new Date(elm.date).setHours(0, 0, 0, 0)).getTime(),
                isWorkingDay: elm.isWorkingDay
            }
            tempWorkingDay.push(demo)

        })
        setWorkingDaya(tempWorkingDay)

    }, [calendar])

    const dashboardDataByID = keyBy(dashboardData, 'timeStamp')
    const workingDaysByID = keyBy(workingDays, 'timeStamp')

    React.useEffect(() => {

        const tempData = [];
        var current_date = new Date();
        var currrnt_time_stamp = current_date.getTime()
        const year = toDate.getFullYear();
        const month = toDate.getMonth();
        let weekendcount = 0;
        let count = 0;
        var daysInMonth = new Date(year, month + 1, 0).getDate();
        for (let i = 1; i <= 31; i++) {
            const date = new Date(year, month, i);
            const dayOfWeek = date.getDay();
            const timeStamp = new Date(date).getTime()

            var demo = {
                dayOfWeek: 1,
                timeStamp: timeStamp,
                index: i,
                value: dashboardDataByID[timeStamp]?.value ? dashboardDataByID[timeStamp]?.value : '',
                color: dashboardDataByID[timeStamp]?.value ? '#dd322a' : '#6fc764'
            };
            if (daysInMonth >= i) {
                if (currrnt_time_stamp > timeStamp) {
                    if (workingDaysByID[timeStamp]) {
                        if (workingDaysByID[timeStamp]?.isWorkingDay) {
                            count++;
                        } else {
                            demo.dayOfWeek = 0
                            demo.value = ''
                            demo.color = "#dde1e5";
                            weekendcount++;
                        }
                    } else if (dayOfWeek === 0 || dayOfWeek === 6) {
                        demo.dayOfWeek = 0
                        demo.value = ''
                        demo.color = "#dde1e5";
                        weekendcount++;
                    } else {
                        count++;
                    }
                } else {
                    demo.dayOfWeek = 0
                    demo.value = ''
                    demo.color = "#5A5A5A";

                }
            } else {
                demo.dayOfWeek = 0
                demo.value = ''
                demo.color = "#5A5A5A";
            }
            tempData.push(demo);
        }
        setGraphData(tempData)
    }, [dashboardData, workingDays, toDate])

    React.useEffect(() => {
        let incident_count = 0;
        let no_incident_count = 0;

        graphData && graphData.forEach((elm) => {
            if (elm.color === '#6fc764') {
                no_incident_count++;
            } else if (elm.color === '#dd322a') {
                incident_count = parseInt(incident_count) + parseInt(elm.value);
            }
        });

        setSafetyCount((prevState) => ({
            ...prevState,
            incident: incident_count,
            no_incident: no_incident_count,
        }));
    }, [graphData]);

    const data = Object.entries(graphData).map(([key, value]) => {
        return (
            {
                name: title + '_' + key,
                value: 1,
                color: value.color,
            })
    })

    return (
        <>
            <div className="card">
                <div className="card-body customecard">
                    <div className="mapbox">
                        <h4>{ucfirst(title)}
                            <Link to={"/dashboard/" + title} className={show_link ? '' : 'd-none'} ><i className="fa fa-expand-alt"></i></Link>
                        </h4>

                    </div>

                    <div style={{ height: "350px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart >
                                <Pie
                                    activeIndex={new Date().getDate() - 1}
                                    activeShape={renderActiveShape}
                                    data={data}
                                    labelLine={false}
                                    outerRadius={170}
                                    fill="#8884d8"
                                    dataKey="value"
                                    isAnimationActive={false}
                                    innerRadius={110}
                                    label={renderCustomizedLabel}
                                    cx="50%"
                                    cy="50%"
                                    startAngle={90}
                                    endAngle={-270}
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={entry.name} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <ul className='mt-3' >
                        <li><i className="reddot"></i>{indexName[title + '_0']}</li>
                        <li><i className="greendot"></i>{indexName[title + '_1']}</li>
                        <li><i className="holiday"></i>Holiday / Non Working Days</li>
                    </ul>

                </div>

            </div>
            <div className="twoblocks">
                <div class="card text-dark">
                    <div class="redno similarno">
                        <span>{safetyDataCount.incident}</span>
                        <p>{indexName[title + '_0']}</p>
                    </div>
                </div>
                <div class="card text-dark">
                    <div class="greenno similarno">
                        <span>{safetyDataCount.no_incident}</span>
                        <p>{indexName[title + '_1']}</p>
                    </div>
                </div>
            </div>
           {/*  <div class="card text-dark text-center">
                <div class="singlerow">
                    <span>{safetyDataCount.no_incident}</span>
                    <p>{indexName[title + '_2']}</p>
                </div>
            </div> */}
        </>
    );
};

export default IncidentGraph;