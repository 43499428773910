import React from 'react'
import Apis from '../../../../api';
import { Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import Modal from 'react-bootstrap/Modal';

const headers = [
    { title: '#', prop: 'id' },
    { title: 'First Name', prop: 'first_name', isFilterable: true, isSortable: true },
    { title: 'Last Name', prop: 'last_name', isFilterable: true, isSortable: true },
    { title: 'Abrv.', prop: 'abrv', isFilterable: true, isSortable: true },
    { title: 'Handle', prop: 'handle', isFilterable: true, isSortable: true },
    { title: 'Email', prop: 'email', isFilterable: true, isSortable: true },
    { title: 'Action', prop: 'action' },

];

const ucfirst = (text) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
}

const MemberTable = ({ data, handleDeleteMember, handleEditTeam, handleForgetPassword }) => {
    const [memberList, setMemberList] = React.useState([])
    const [attribute_popup, setAtteributePopup] = React.useState(false);

    const [state, setState] = React.useState({
        member_id: '',
        role: '',
        dashboardData: [
            { name: 'safety', read: false, write: false },
            { name: 'quality', read: false, write: false },
            { name: 'cost', read: false, write: false },
            { name: 'people', read: false, write: false },
            { name: 'delivery', read: false, write: false },
        ]
    });

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 1;
        const user = JSON.parse(localStorage.getItem('user-info'))      
        if (data.length > 0) {
            const members = data.member
            members.map((elm, key) => {
                if (elm.status === 1 && elm.role !== 'admin') {
                    if (elm.is_registered === false) {
                        demo = {
                            id: i,
                            first_name: elm.first_name,
                            last_name: elm.last_name,
                            abrv: elm.abrv,
                            handle: elm.handle,
                            email: elm.email,
                            is_registered: '#ffa500',
                            status: false,
                            action: <><div className="d-flex addremove_btn">
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Resend" onClick={() => handleForgetPassword(elm.email)}>Resend</button>
                            </div></>
                        }
                        if (user.role === 'admin') {
                            demo.action = <><div className="d-flex addremove_btn">
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Resend" onClick={() => handleForgetPassword(elm.email)}>Resend</button>
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Premission" onClick={() => handleLinkRole(elm)}><i className="fas fa-solid fa-link"></i></button>
                            </div></>
                        }
                    } else {
                        demo = {
                            id: i,
                            first_name: elm.first_name,
                            last_name: elm.last_name,
                            abrv: elm.abrv,
                            handle: elm.handle,
                            email: elm.email,
                            is_registered: false,
                            status: false,
                            action: <><div className="d-flex addremove_btn">
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                            </div></>

                        }
                        if (user.role === 'admin') {
                            demo.action = <><div className="d-flex addremove_btn">
                                <button type="button" className="btn btn-primary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#Members" data-bs-whatever="@mdo" data-bs-placement="bottom" title="Edit" onClick={() => handleEditTeam(elm, 'member')}><i className="fas fa-pencil-alt"></i></button>
                                <button className="btn btn-primary btn-sm me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteMember(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Premission" onClick={() => handleLinkRole(elm)}><i className="fas fa-solid fa-link"></i></button>
                            </div></>
                        }
                    }

                    list.push(demo)
                    i++
                } else {
                    if (elm.role !== 'admin') {
                        demo = {
                            id: i,
                            first_name: elm.first_name,
                            last_name: elm.last_name,
                            abrv: elm.abrv,
                            handle: elm.handle,
                            email: elm.email,
                            is_registered: elm.is_registered,
                            status: 'line-through',
                            action: <> <div className="d-flex addremove_btn">
                                <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteMember(elm._id, 1)} ><i class="fas fa-trash-restore"></i></button>
                            </div></>

                        }
                        list.push(demo)
                        i++
                    }
                }
                return list
            })
        }
        setMemberList(list)
    }, [data])

    const handleLinkRole = (member) => {
        Apis.Getpermissions(member._id).then((response) => {
            if (response.data.success) {
                const permissions = response.data.data;

                setState(prevState => ({
                    ...prevState,
                    member_id: member._id,
                    role: member.role,
                    dashboardData: prevState.dashboardData.map(item => {
                        const found = permissions.find(p => p.section === item.name);
                        return found ? { ...item, read: found.read, write: found.write } : item;
                    })
                }));
            }
        }).catch((error) => {
            setState(prevState => ({
                ...prevState,
                member_id: member._id,
                role: member.role

            }))
        })
        setAtteributePopup(true)
    }

    const handleCheckboxChange = (index, field) => {
        setState(prevState => ({
            ...prevState,
            dashboardData: prevState.dashboardData.map((item, i) =>
                i === index ? { ...item, [field]: !item[field] } : item
            )
        }));
    };

    const handleSavePermission = () => {
        const raw = {
            member_id: state.member_id,
            role: state.role,
            module: 'dashboardData',
            section: state.dashboardData
        }
        Apis.AddUserPermission(raw).then((res) => {
            setState({
                member_id: '',
                role: '',
                dashboardData: [
                    { name: 'safety', read: false, write: false },
                    { name: 'quality', read: false, write: false },
                    { name: 'cost', read: false, write: false },
                    { name: 'people', read: false, write: false },
                    { name: 'delivery', read: false, write: false },
                ]
            });
            setAtteributePopup(false)
        }).catch((error) => {

        })

    }



    return (
        <>
            <DatatableWrapper
                body={memberList}
                headers={headers}
                paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 15,
                        options: [15, 25, 50, 100, 200]
                    }
                }}>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >
                        <Filter placeholder="Type here to search" />
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody rowProps={(row) => ({
                        style: { background: row.is_registered, textDecorationLine: row.status }
                    })} />
                </Table>
                <Row className="mb-4 p-2">
                    <Col
                        xs={12}
                        lg={8}
                        className="d-flex flex-col justify-content-start align-items-end"
                    >
                        <Row>
                            <Col xs={12}
                                lg={8}
                                className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                    paginationRange={2}
                                /></Col>
                            <Col xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                        </Row>
                    </Col>

                    <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                    >

                    </Col>
                </Row>
            </DatatableWrapper>
            <Modal show={attribute_popup} aria-labelledby="contained-modal-title-vcenter" onHide={() => setAtteributePopup(!attribute_popup)} centered >
                <Modal.Header closeButton>
                    <Modal.Title> User Access Management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Dashboard Data</button>
                                </li>
                            </ul>
                            <div className="tab-content mt-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Page</th>
                                                        <th>Read</th>
                                                        <th>Write</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.dashboardData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{ucfirst(item.name)}</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="mx-2">
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                onChange={() => handleCheckboxChange(index, 'read')}
                                                                                checked={item.read}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="mx-2">
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                onChange={() => handleCheckboxChange(index, 'write')}
                                                                                checked={item.write}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSavePermission()} > Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setAtteributePopup(!attribute_popup)}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MemberTable;
