import React, { useEffect, useState, useRef } from 'react'
import { LineChart, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts'
import createTrend from "trendline"
import keyBy from 'lodash/keyBy'
import Apis from '../../../../api'

const dateFormatter = (value) =>

    new Date(value).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',

    })

const LineChartMargin = {
    top: 5,
    right: 30,
    left: 20,
    bottom: 30
}

const XAxisPading = { left: 60, right: 60 }
const XAxisTick = { fontSize: '0.8em' }
const YAxisDomain = [0, 100]
const LineActiveDot = { r: 8 }
const LegendWrapperStyle = { lineHeight: 4 }
const labelFormatter = (value) => new Date(value).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',

})

const findArryIndex = (arr, date, id) => {
    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.team_id === id && el.closing_date === date) {
            return i
        }
    }
    return -1
}

const Ppcgraph = ({ teamId, fromDate, toDate, teams, subproject, phase_plane, work_packages, location_id, showTrendLine = false, showMinMAxLine = false, showMcosLine = false }) => {

    const [commitmentPlans, setCommitmentPlans] = useState([])
    const [commitmentPlan, setCommitmentPlan] = useState([])
    const [PPCGraphData, setPPCGraphData] = useState([])
    const [graphData, setgraphData] = useState([])
    const [show_issue_list, setShowIssueList] = useState(0);

    const teamsById = keyBy(teams, '_id')
    useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const title_data = data.find(setting => setting.key === 'reliability');
                const graph = title_data ? parseInt(title_data.value) : 0;
                setShowIssueList(graph);
            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [])
    useEffect(() => {
        const filter = [{ is_active: false }]
        let filters = [{ type: 'Task' }]
        let demo
        if (teamId && teamId !== '$$all') {
            demo = { team_id: teamId }
            filter.push(demo)
        }
        if (subproject && subproject.value !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane.value !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages.value !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (fromDate) {
            demo = { closing_date: { $gte: new Date(fromDate).getTime() } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { closing_date: { $lte: new Date(toDate).getTime() } }
            filter.push(demo)
        }
        if (location_id) {
            demo = { location_id: location_id }
            filters.push(demo)
        }

        const raw = {
            "filter": filter,
            "filters": filters
        }
        Apis.getAnalytice(raw).then((res) => {
            setCommitmentPlans(res.data)
        }).catch((error) => {
            console.log(error.response.data)
        })

    }, [fromDate, teamId, toDate, subproject, phase_plane, work_packages, location_id])

    useEffect(() => {
        let list = []
        const plans = commitmentPlans.commit ? commitmentPlans.commit : []
        const tasks = commitmentPlans.tasks ? commitmentPlans.tasks : []
        plans.sort((a, b) => (a.closing_date > b.closing_date) ? 1 : ((b.closing_date > a.closing_date) ? -1 : 0))
        plans.map((p) => {
            const task = tasks.find(elm => elm._id === p.task_id)
            const closing_date = new Date(new Date(p.closing_date).setHours(0, 0, 0, 0)).getTime()
            if (task) {
                let demo = {
                    closing_date: closing_date,
                    team_id: p.team_id,
                    completed_tasks_count: p.completed_tasks_count,
                    not_completed_tasks_count: p.not_completed_tasks_count
                }
                if (list.length === 0) {
                    list.push(demo)
                } else {
                    let index = findArryIndex(list, closing_date, p.team_id)
                    if (index === -1) {
                        list.push(demo)
                    } else {
                        list[index].completed_tasks_count = parseInt(list[index].completed_tasks_count) + parseInt(p.completed_tasks_count)
                        list[index].not_completed_tasks_count = parseInt(list[index].not_completed_tasks_count) + parseInt(p.not_completed_tasks_count)
                    }
                }
            }
            return list
        })
        setCommitmentPlan(list)
    }, [commitmentPlans])

    useEffect(() => {
        const graphData = []
        if (commitmentPlan.length !== 0) {
            commitmentPlan.map((p) => {
                let demo = {
                    date: p.closing_date,
                    amount:
                        p.completed_tasks_count === 0 && p.not_completed_tasks_count === 0
                            ? 0
                            : (p.completed_tasks_count / (p.completed_tasks_count + p.not_completed_tasks_count)) * 100,

                    [p.team_id]:
                        p.completed_tasks_count === 0 && p.not_completed_tasks_count === 0
                            ? 0
                            : (p.completed_tasks_count / (p.completed_tasks_count + p.not_completed_tasks_count)) * 100

                }
                graphData.push(demo)
                return graphData
            })
        }
        setgraphData(graphData)
    }, [commitmentPlan])

    useEffect(() => {
        const list = []
        if (teamId && teamId !== '$$all') {
            setPPCGraphData(graphData)
        } else {
            graphData.map((graphdata) => {
                const filterdata = graphData.filter(elm => elm.date === graphdata.date)
                const data = filterdata.map((amount) => amount.amount)
                const average = list => list.reduce((prev, curr) => prev + curr) / list.length;
                const max = Math.max(...data);
                const min = Math.min(...data);
                const avg = average(data)
                let demo = {
                    date: graphdata.date,
                    min: min,
                    max: max,
                    avg: avg,
                    mcos: show_issue_list,
                }

                if (list.length === 0) {
                    list.push(demo)
                } else {
                    const test = list.find(elm => elm.date === graphdata.date)
                    if (!test) {
                        list.push(demo)
                    }
                }
                return list
            })
            setPPCGraphData(list)
        }




    }, [graphData, teamId])

    const timestamps = PPCGraphData.map((data) => data.date);
    const xMax = Math.max(...timestamps);
    const xMin = Math.min(...timestamps);
    const trendMinData = () => {
        const trend = createTrend(PPCGraphData, "date", "min");
        return [
            { 'Linear (PPC (min))': trend.calcY(xMin), date: xMin },
            { 'Linear (PPC (min))': trend.calcY(xMax), date: xMax }
        ];
    };
    const trendMaxData = () => {
        const trend = createTrend(PPCGraphData, "date", "max");
        return [
            { 'Linear (PPC (max))': trend.calcY(xMin), date: xMin },
            { 'Linear (PPC (max))': trend.calcY(xMax), date: xMax }
        ];
    };
    const trendAvgData = () => {
        const trend = createTrend(PPCGraphData, "date", "avg");
        return [
            { 'Linear (PPC (Average))': trend.calcY(xMin), date: xMin },
            { 'Linear (PPC (Average))': trend.calcY(xMax), date: xMax }
        ];
    };
    const trendData = () => {
        const trend = createTrend(PPCGraphData, "date", "amount");
        return [
            { 'amount': trend.calcY(xMin), date: xMin },
            { 'amount': trend.calcY(xMax), date: xMax }
        ];
    };
    const wrapperRef = useRef()

    return (
        <>
            <div style={{ height: '600px' }}>
                <ResponsiveContainer width="100%" height="100%" ref={wrapperRef}>
                    <LineChart
                        margin={LineChartMargin}
                        width={wrapperRef.current?.clientWidth}
                        data={PPCGraphData}>
                        {/* <CartesianGrid stroke='#f8f8f8' vertical={false} /> */}
                        <XAxis
                            name="Time"
                            type="number"
                            dataKey="date"
                            domain={["dataMin", "dataMax"]}
                            tickFormatter={dateFormatter}
                            angle={25}
                            dy={15}
                            dx={25}
                            axisLine={true}
                            tick={XAxisTick}
                            height={70}
                            fill="#5b626b"
                            tickLine={false}
                            padding={XAxisPading}
                            interval="preserveStartEnd"
                        /*  label={XAxisLabel} */
                        />
                        <YAxis
                            tickFormatter={(value) => Math.round(value)}
                            tickCount={12}
                            axisLine={false}
                            tickLine={false}
                            /* label={YAxisLabel} */
                            type="number"
                            allowDecimals={true}
                            domain={YAxisDomain} />
                        <Tooltip
                            labelFormatter={labelFormatter}
                            formatter={(value, name) => [value ? `${Math.round(value)}%` : `0%`, name === 'amount' ? "Trend Line" : teamsById[name]?.name ? teamsById[name]?.name : name]}
                        />

                        <Legend
                            wrapperStyle={LegendWrapperStyle}
                            verticalAlign="top"
                            formatter={(value) => value === 'amount' ? "Trend Line" : teamsById[value]?.name ? teamsById[value]?.name : value}
                        />
                        {
                            teamId && teamId !== '$$all' ? <></> : showMinMAxLine ? <><Line
                                activeDot={LineActiveDot}
                                connectNulls
                                key="min"
                                stroke="#f5a623"
                                fill="#f5a623"
                                type="monotoneX"
                                dataKey="min"
                                strokeWidth={2}
                            />
                                <Line
                                    activeDot={LineActiveDot}
                                    connectNulls
                                    key="max"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                    type="monotoneX"
                                    dataKey="max"
                                    strokeWidth={2}
                                />
                                <Line
                                    activeDot={LineActiveDot}
                                    connectNulls
                                    key="avg"
                                    stroke="#417505"
                                    fill="#417505"
                                    type="monotoneX"
                                    dataKey="avg"
                                    strokeWidth={2}
                                /></> : <>
                                <Line
                                    activeDot={LineActiveDot}
                                    connectNulls
                                    key="avg"
                                    stroke="#417505"
                                    fill="#417505"
                                    type="monotoneX"
                                    dataKey="avg"
                                    strokeWidth={2}
                                />
                            </>
                        }

                        {
                            teamId && teamId !== '$$all' ? <></> : showMcosLine ? <> <Line
                                activeDot={LineActiveDot}
                                dot={false}
                                connectNulls
                                key="mcos"
                                stroke="#EC1111"
                                fill="#EC1111"
                                type="monotoneX"
                                dataKey="mcos"
                                strokeWidth={2}
                            /> </> : <></>
                        }

                        {teams.map((t) => {
                            if (teamId && teamId !== '$$all') {
                                if (t._id === teamId) {
                                    return (
                                        <Line
                                            activeDot={LineActiveDot}
                                            connectNulls
                                            key={t._id}
                                            stroke={t.color}
                                            fill={t.color}
                                            type="monotoneX"
                                            dataKey={t._id}
                                            strokeWidth={2}

                                        />
                                    )
                                }
                            }
                        })}

                        {showTrendLine ?
                            teamId && teamId !== '$$all' ? <>
                                <Line
                                    data={trendData()}
                                    dataKey="amount"
                                    stroke="black"
                                    strokeDasharray="5 5"

                                /></> :
                                showMinMAxLine ? <>
                                    <Line
                                        data={trendMinData()}
                                        dataKey="Linear (PPC (min))"
                                        stroke="#f5a623"
                                        strokeDasharray="5 5"
                                    /> <Line
                                        data={trendMaxData()}
                                        dataKey="Linear (PPC (max))"
                                        stroke="#8884d8"
                                        strokeDasharray="5 5"
                                    /> <Line
                                        data={trendAvgData()}
                                        dataKey="Linear (PPC (Average))"
                                        stroke="#417505"
                                        strokeDasharray="5 5"
                                    /></> :
                                    <>
                                        <Line
                                            data={trendAvgData()}
                                            dataKey="Linear (PPC (Average))"
                                            stroke="#417505"
                                            strokeDasharray="5 5"
                                        />
                                    </> : <></>}




                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default Ppcgraph


